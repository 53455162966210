<template>
    <!--   Lucky Wheel  -->
    <div class="c-components_body unselectable" style="background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));z-index: 29;">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   bg static  -->
            <div class="c-lucky"
                 :style="{'width': 1150*ss*xMult+'px','height': 1010*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':4*ss*xMult+'px solid '+getBorder,'background':getBg,'box-shadow':getShadow,
                    'opacity':opacityVal}"
            >

                <!--     decoration images for scratch spin    -->
                <div v-if="this.getScratchStyle()" class="c-lucky_bg_abs"
                     :style="{'width': '100%','height': '100%','border-radius':60*ss*xMult+'px',}"
                >
                    <img class="c-lucky_img_1" alt=""
                         :style="{'width': 1150*ss*xMult+'px','height': 'auto','bottom': 5*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="img_podium"
                    />
                    <img v-if="this.getScratchStyle() && getScratch" class="c-lucky_img_1" alt=""
                         :style="{'width': 'auto','height': 900*ss*xMult+'px','top': 100*ss*xMult+'px','left': 30*ss*xMult+'px',}"
                         :src="img_balloons_2"
                    />
                </div>
                <!--     decoration images for chips spin    -->
                <div v-else class="c-lucky_bg_abs"
                     :style="{'width': '100%','height': '100%','border-radius':60*ss*xMult+'px',}"
                >
                    <img class="c-lucky_img_1" alt=""
                         :style="{'width': 'auto','height': 350*ss*xMult+'px','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
                         :src="img_1"
                    />
                    <img class="c-lucky_img_1" alt=""
                         :style="{'width': 'auto','height': 350*ss*xMult+'px','top': 0*ss*xMult+'px','right': 0*ss*xMult+'px',}"
                         :src="img_2"
                    />
                    <img class="c-lucky_img_1" alt=""
                         :style="{'width': 'auto','height': 300*ss*xMult+'px','bottom': -50*ss*xMult+'px','left': -50*ss*xMult+'px',}"
                         :src="img_3"
                    />
                    <img class="c-lucky_img_1" alt=""
                         :style="{'width': 'auto','height': 300*ss*xMult+'px','bottom': -50*ss*xMult+'px','right': -50*ss*xMult+'px',}"
                         :src="img_4"
                    />
                </div>

                <!--     Lucky wheel text       -->
                <div class="c-lucky_column"
                     :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px','margin-top': 30*ss*xMult+'px',}"
                >
                    <div class="c-lucky_text"
                         :style="{'width': 1000*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                    >
                        {{$t('luckyMsg.m052_01')}}
                    </div>
                </div>

                <!--     SHOW PRIZE REGION     -->
                <div v-if="winResult" class="c-lucky_wheel_reg"
                    :style="{'width': 1000*ss*xMult+'px','height': 700*ss*xMult+'px','gap': 20*ss*xMult+'px',}">
                    <img class="c-lucky_column" alt=""
                         :style="{'width': 'auto','height': 340*ss*xMult+'px',}"
                         :src="getWinImage"
                    />
                    <div class="c-lucky_column" style="justify-content: flex-start;"
                         :style="{'width': 900*ss*xMult+'px','height': 'auto',}"
                    >
                        <div class="c-lucky_text" style="white-space: normal;text-align: -webkit-center;"
                             :style="{'width': 900*ss*xMult+'px','height': 'auto',
                            'font-size': 60*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{winResult}}
                        </div>
                    </div>
                    <!--     OK Btn       -->
                    <ButtonSvgOval
                                   :btn-text="$t('luckyMsg.m_ok')"
                                   :bg-width="300*ss*xMult" :bg-height="90*ss*xMult"
                                   :font-size="45*ss*xMult"
                                   :backgroundVal="this.getScratchStyle()?'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(94.81deg,#dc06ff -6.49%,#8606b3 108.32%) var(--x,0)/200%'
                                                                         :'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                                   :borderVal="this.getScratchStyle()?'linear-gradient(102.76deg,'+'#88028D'+' 3.6%,'+'#490f40'+' 53.89%,'+'#88028D'+' 99.19%)'
                                                                     :'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                                   :callback="btn_xClick" :callback-wait="true"
                                   :click-scale="0.9"
                                   :ss="ss"
                    />
                </div>
                <!--     WHEEL  REGION     -->
                <div v-else-if="newSpinDelay===0 || getScratch" class="c-lucky_wheel_reg"
                    :style="{'width': 1000*ss*xMult+'px','height': 800*ss*xMult+'px',}">
                    <!--     wheel       -->
                    <div class="c-lucky_bg_abs"
                         :style="{'width': 800*ss*xMult+'px','height': 800*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                    >
                        <div class="c-lucky_column"
                             :style="{'width': 800*ss*xMult+'px','height': 800*ss*xMult+'px',
                                'rotate': curRotate+'deg',}"
                        >
                            <!--     Wheel bg     -->
                            <img class="c-lucky_bg_abs"
                                 :src="imgRing" alt=""
                                 :style="{'width': 800*ss*xMult+'px','height': 800*ss*xMult+'px',}"
                            />

                            <!--     wheel items       -->
                            <div v-for="(item, index) in getScratch?luckyConfig.scratch:luckyConfig.chips"
                                 :key="index"
                                 class="c-lucky_bg_abs"
                                 :style="{'width': 800*ss*xMult+'px','height': 800*ss*xMult+'px','top': 0*ss*xMult+'px',
                            'rotate': 36*index+'deg',}"
                            >
                                <div class="c-lucky_column"
                                     :style="{'width': 800*ss*xMult+'px','height': 800*ss*xMult+'px'}"
                                >
                                    <!--     shadow zone       -->
                                    <img v-if="index % 2 === 1" class="c-lucky_bg_abs"
                                         :src="img_zone" alt=""
                                         :style="{'width': 'auto','height': 155*ss*xMult+'px','top': 25*ss*xMult+'px',}"
                                    />

                                    <!--     wheel item text       -->
                                    <div v-if="item.msg" class="c-lucky_bg_abs"
                                         :style="{'width': 'auto','height': 40*ss*xMult+'px','top': 35*ss*xMult+'px',}"
                                    >
                                        <div class="c-lucky_text"
                                             :style="{'width': 'auto','height': 40*ss*xMult+'px',
                                    'font-size': 35*ss*xMult+'px','line-height': 40*ss*xMult+'px',}"
                                        >
                                            {{item.msg}}
                                        </div>
                                    </div>

                                    <img class="c-lucky_bg_abs"
                                         :src="item.img" alt=""
                                         :style="{'width': 'auto','height': (item.msg?70:120)*ss*xMult+'px','top': (item.msg?85:35)*ss*xMult+'px',}"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    <!--     Select image (green square)      -->
                    <div class="c-lucky_bg_abs"
                         :style="{'width': 800*ss*xMult+'px','height': 800*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                    >
                        <img class="c-lucky_bg_abs"
                             :src="img_select" alt=""
                             :style="{'width': 'auto','height': 170*ss*xMult+'px','top': 15*ss*xMult+'px',}"
                        />
                    </div>

                    <!--     Spin button       -->
                    <div class="c-lucky_bg_abs"
                         :style="{'width': 800*ss*xMult+'px','height': 800*ss*xMult+'px','top': 0*ss*xMult+'px',}"
                    >
                        <ButtonSvg :svg-normal="btnSpin"
                                   :bg-width="320*ss*xMult"
                                   :bg-height="320*ss*xMult"
                                   :callback="clickSpin" :callback-wait="true"
                        />
                    </div>

                    <!--     Ballooon image under wheel for scratch spin    -->
                    <img v-if="getScratch" class="c-lucky_bg_abs c-lucky_img_1" alt=""
                         :style="{'height': 'auto','width': 200*ss*xMult+'px','top': -10*ss*xMult+'px','left': 35*ss*xMult+'px',}"
                         :src="img_balloons_1"
                    />
                    <img v-if="getScratch" class="c-lucky_bg_abs c-lucky_img_1" alt=""
                         :style="{'height': 'auto','width': 190*ss*xMult+'px','top': 205*ss*xMult+'px','right': -35*ss*xMult+'px',}"
                         :src="img_balloons_3"
                    />
                </div>
                <!--     TIME FOR NEXT SPIN REGION     -->
                <div v-else-if="newSpinDelay>0 || !getScratch" class="c-lucky_wheel_reg"
                     :style="{'width': 1000*ss*xMult+'px','height': 700*ss*xMult+'px',}">
                    <!--     You've already Spin at this hour       -->
                    <div class="c-lucky_column" style="justify-content: flex-start;"
                         :style="{'width': 900*ss*xMult+'px','height': 230*ss*xMult+'px',}"
                    >
                        <div class="c-lucky_text" style="white-space: normal;text-align: -webkit-center;"
                             :style="{'width': 900*ss*xMult+'px','height': 200*ss*xMult+'px',
                            'font-size': 60*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                        >
                            {{$t('luckyMsg.m052_03')}}
                        </div>
                    </div>
                    <!--     Time for next Spin       -->
                    <div class="c-lucky_time_bg"
                         :style="{'width': 600*ss*xMult+'px','height': 220*ss*xMult+'px','margin-bottom': 20*ss*xMult+'px',
                            'border-radius': 100*ss*xMult+'px',}"
                    >
                        <div class="c-lucky_time_text c-lucky_column"
                             :style="{'width': 500*ss*xMult+'px','height': 50*ss*xMult+'px','font-size': 45*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                            {{$t('luckyMsg.m052_02')}}
                        </div>

                        <div class="c-lucky_row"
                             :style="{'width': 500*ss*xMult+'px','height': 120*ss*xMult+'px','gap': 10*ss*xMult+'px',}">
                            <div class="c-lucky_column"
                                 :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                                <div class="c-lucky_time_text c-lucky_column" style="font-weight: 700"
                                     :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                                    {{getHours}}
                                </div>
                                <div class="c-lucky_time_text c-lucky_column" style="opacity: 0.6;"
                                     :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                                    {{$t('luckyMsg.m030_7')}}
                                </div>
                            </div>
                            <div class="c-lucky_column"
                                 :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                                <div class="c-lucky_time_text c-lucky_column" style="font-weight: 700"
                                     :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                                    {{getMin}}
                                </div>
                                <div class="c-lucky_time_text c-lucky_column" style="opacity: 0.6;"
                                     :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                                    {{$t('luckyMsg.m030_8')}}
                                </div>
                            </div>

                            <div class="c-lucky_column"
                                 :style="{'width': 160*ss*xMult+'px','height': '100%','gap': 10*ss*xMult+'px',}">
                                <div class="c-lucky_time_text c-lucky_column" style="font-weight: 700"
                                     :style="{'width': '100%','height': 70*ss*xMult+'px','font-size': 65*ss*xMult+'px','line-height': 70*ss*xMult+'px',}">
                                    {{getSec}}
                                </div>
                                <div class="c-lucky_time_text c-lucky_column" style="opacity: 0.6;"
                                     :style="{'width': '100%','height': 30*ss*xMult+'px','font-size': 30*ss*xMult+'px','line-height': 30*ss*xMult+'px',}">
                                    {{$t('luckyMsg.m030_9')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
      <error-popup
        :open="errorPopup"
        :title="titleErrorPopup"
        :text="textErrorPopup"
        @closeErrorPopup="errorPopup=false"
    />
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvg from "./ButtonSvg";
    import ButtonSvgOval from "./ButtonSvgOval";
    import LuckyConfig from "./LuckyConfig";
    import { scratchCards } from "../../constants/index";

    import btn_xSvg from "../../assets/svg-icons/lucky/btn_x.svg";
    import btn_spin from "../../assets/svg-icons/lucky/btn_spin.svg";
    import btn_spin2 from "../../assets/svg-icons/lucky/btn_spin2.svg";
    import img_select from "../../assets/svg-icons/lucky/select.svg";
    import img_zone from "../../assets/svg-icons/lucky/zone.svg";
    import img_ring from "../../assets/svg-icons/lucky/ring.svg";
    import img_ring2 from "../../assets/svg-icons/lucky/ring2.svg";
    import img_1 from "../../assets/images/luckyWheel/1.webp";
    import img_2 from "../../assets/images/luckyWheel/2.webp";
    import img_3 from "../../assets/images/luckyWheel/3.webp";
    import img_4 from "../../assets/images/luckyWheel/4.webp";
    import img_balloons_1 from "../../assets/images/luckyWheel/balloons_1.webp";
    import img_balloons_2 from "../../assets/images/luckyWheel/balloons_2.webp";
    import img_balloons_3 from "../../assets/images/luckyWheel/balloons_3.webp";
    import img_podium from "../../assets/images/luckyWheel/podium.webp";

    import img_chips_0 from "../../assets/images/luckyWheel/chips_0.webp";
    import img_chips_1 from "../../assets/images/luckyWheel/chips_1.webp";
    import img_chips_2 from "../../assets/images/luckyWheel/chips_2.webp";
    import img_chips_3 from "../../assets/images/luckyWheel/chips_3.webp";
    import img_chips_4 from "../../assets/images/luckyWheel/chips_4.webp";
    import errorPopup from "@/components/popups/error-popup.vue";

    export default defineComponent({
        name: 'LuckyWheel',
        computed: {
            getScratch(){         //if current game scratch then return true
                return this.getScratchGame();
            },

            getBg(){
                let txt='linear-gradient(93.92deg, #00C6FB 0.27%, #005BEA 105.74%)';
                let s='linear-gradient(178.92deg, '+'rgb(3,68,95)'+' 0.27%, '+'rgb(3, 15, 32)'+' 105.74%)'
                txt=this.getScratchStyle()?s:txt;
                return txt;
            },
            getBorder(){
                let txt='#3585FF';
                txt=this.getScratchStyle()?'#000000':txt;
                return txt;
            },
            getShadow(){
                let txt='0px 0px 100px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 100px 0px rgba(0, 91, 234, 0.60)';
                txt=this.getScratchStyle()?'none':txt;
                return txt;
            },
            imgRing(){
                return this.getScratchGame()?img_ring2:img_ring;
            },
            btnSpin(){
                return this.getScratchGame()?btn_spin2:btn_spin;
            },
            getHours() {
                let timeRemaining = 0;
                timeRemaining = this.newSpinDelay;
                timeRemaining /= (1000 * 60 * 60 );
                timeRemaining %= 24;
                timeRemaining = Math.floor(timeRemaining);
                return timeRemaining.toString();
            },
            getMin(){
                let timeRemaining = 0;
                timeRemaining = this.newSpinDelay;
                timeRemaining /= (1000 * 60  );
                timeRemaining %= 60;
                timeRemaining = Math.floor(timeRemaining);
                return timeRemaining.toString();
            },
            getWinImage(){      //return image for winResult
                let img='';
                try{
                    //wheel: {"prize":{"prizeType":"scratch","prizeAmount":1,"game":"elephant"}}    //Scratch spin result
                    //wheel: {"prize":{"prizeType":"chips","prizeAmount":5000,"game":null}}         //Chips win
                    const prize=this.lastWheelSpinPrize;
                    if (prize.prizeType==='scratch'){
                        for (let i=0;i<this.luckyConfig.scratch.length;i++)
                            if (this.luckyConfig.scratch[i].name===prize.game)
                                img=this.luckyConfig.scratch[i].img;   //todo
                    }
                    if (prize.prizeType==='chips'){
                        img=img_chips_4;
                        img=prize.prizeAmount<100000?img_chips_3:img;
                        img=prize.prizeAmount<50000?img_chips_2:img;
                        img=prize.prizeAmount<10000?img_chips_1:img;
                        img=prize.prizeAmount<1000?img_chips_0:img;
                    }
                }
                catch (e) {
                    console.log('LuckyWheel.getWinImage catch: ',e.message,'\nstack: ',e.stack)
                }
                return img;
            },
            getSec(){
                let txt=0;
                txt=(Math.floor(this.newSpinDelay/1000)%60).toString()

                return txt;
            },
        },
        components: {errorPopup, ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:btn_xSvg,img_1:img_1,
                img_2:img_2,img_3:img_3, img_4:img_4,
                img_select:img_select, img_zone:img_zone,
                img_balloons_1:img_balloons_1,img_balloons_2:img_balloons_2,
                img_balloons_3:img_balloons_3,img_podium:img_podium,
                curRotate:0,
                lastWheelSpinPrize:null,
                nextWheelSpinTimestamp:null,
                opacityVal:1,       //popup opacity
                startSpin:false,    //
                newSpinDelay:0,     //delay in ms for new spin
                winResult:null,     //Show win, if not null
                luckyConfig:LuckyConfig,
                ss:1,realWidth:1920,realHeight:1080,getLeft:0,xMult:0.5,
                wheel:null,
                errorPopup: false,
                textErrorPopup: "",
                titleErrorPopup: "Error",
            };
        },
        created() {
            window.addEventListener("resize", () => {
                this.resize();
            });
            this.resize();
            this.newSpinInterval=null;
        },
        beforeMount() {
            const state=this.$store.state;
            this.startSpin=false;
            this.opacityVal=0;
            this.curRotate = 0;
            this.winResult=null;

            const lwc=state.blockspinState.luckyWheelChipsWinAmounts;
            const luckyConfig=this.luckyConfig;
            lwc.forEach((item,index)=>{
                luckyConfig.chips[index].msg=item===0?'Losing':item.toLocaleString();
                luckyConfig.chips[index].name=item.toString();
            });

            this.$store.dispatch("getUser").then(()=>{
                //state.userData.lastWheelSpinPrize={"prizeType":"scratch","prizeAmount":1,"game":null};      //test
                this.lastWheelSpinPrize=state.userData.lastWheelSpinPrize;
                this.nextWheelSpinTimestamp=state.userData.nextWheelSpinTimestamp;
                this.startTimeControl();

                if (this.nextWheelSpinTimestamp) MyLog('nextWheelSpinTimestamp-Date.now: '+(this.nextWheelSpinTimestamp-Date.now()))
                if (this.lastWheelSpinPrize) MyLog('lastWheelSpinPrize: '+JSON.stringify(this.lastWheelSpinPrize))
            });
            TweenMax.to(this,{opacityVal:1,duration:1,delay:0.2,ease: "power2.out",});
        },
        beforeUnmount() {
            this.stopTimeControl();
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupLuckyWheel.visible=false;
            },
            //Animate spin
            //Make 1 rotation for 360 degrees
            // if receive answer from backend, calculate final angle and finish rotation
            // if no answer from backend make next rotation for 360 degrees
            animateSpin(){
                //Make prespin with 1 rotation for 360 degrees
                const tlPrespin=TweenMax.timeline({repeat:0, onComplete:()=>{
                        MyLog('prespin complete');
                        this.curRotate=0;
                        if (this.wheel){            //if receive answer from backend then finish spin
                            this.finishSpin();
                        }
                        else{                       //if no answer from backend then continue spining
                            const tlSpin=TweenMax.timeline({repeat:-1, onRepeat:()=>{
                                    this.curRotate=0;

                                    //this.wheel={"prize":{"prizeType":"scratch","prizeAmount":1,"game":null}};        //test
                                    //this.wheel={"prize":{"prizeType":"scratch","prizeAmount":1,"game":"elephant"}}
                                    //this.wheel={"prize":{"prizeType":"scratch","prizeAmount":1,"game":"panda"}}
                                    //this.wheel={"prize":{"prizeType":"chips","prizeAmount":5000,"game":null}}

                                    if (this.wheel){            //if receive answer from backend then finish spin
                                        tlSpin.kill();
                                        this.finishSpin();
                                    }
                                }
                            });
                            tlSpin.to(this, {curRotate: 360, duration: 1, ease: "none",});
                        }
                    }
                });
                tlPrespin.to(this, {curRotate: -10, duration: 0.3, ease: "power1.out",});
                tlPrespin.to(this, {curRotate: 0, duration: 0.2, ease: "power1.in",},">");
                tlPrespin.to(this, {curRotate: 360, duration: 1, ease: "none",},">");

            },
            // calculate final angle and finish rotation
            finishSpin(){
                const state=this.$store.state;

                if (!this.wheel.error) {     //Normal result
                    let numb=this.getWinNumber(this.wheel);
                    let dur=1+0.1*(10-numb);
                    let angle=360+36*(10-numb);
                    MyLog('numb='+numb);

                    const tlFinish=TweenMax.timeline({repeat:0, onComplete:()=>{
                            this.completeSpin(this.wheel);
                        }
                    });
                    tlFinish.to(this, {curRotate: angle, duration: dur, ease: "none",});
                    tlFinish.to(this, {curRotate: angle+10, duration: 0.2, ease: "power1.out"},">");
                    tlFinish.to(this, {curRotate: angle, duration: 0.3, ease: "power1.in",},">");

                }
                else{
                    this.errorPopup=true
                    this.textErrorPopup=this.wheel.error
                    this.startSpin=false;
                    this.wheel=null;
                }
            },

            clickSpin(){
                if (this.startSpin) return;
                this.wheel=null;
                this.startSpin=true;
                let spinScratch=this.getScratchGame();
                this.animateSpin();
                this.$store.dispatch("spinWheel", spinScratch).then((wheel)=>{
                    this.wheel=wheel;
                    MyLog('spinWheel('+spinScratch.toString()+')');
                    MyLog('wheel:');
                    MyLog(JSON.stringify(wheel));
                });//*/
            },
            completeSpin(wheel){
                //wheel: {"prize":{"prizeType":"scratch","prizeAmount":1,"game":null}}          //win scratch card spin
                //wheel: {"prize":{"prizeType":"scratch","prizeAmount":1,"game":"elephant"}}    //Scratch spin result
                //wheel: {"prize":{"prizeType":"chips","prizeAmount":5000,"game":null}}         //Chips win
                //wheel: {"prize":{"prizeType":"chips","prizeAmount":0,"game":null}}            //Loosing

                const state=this.$store.state;

                //if scratch card coming
                if (this.getScratchGame(wheel)){
                    TweenMax.to(this,{opacityVal:0,duration:0.5,deleay:0.5,ease: "power1.in",       //set opacity to 0
                        onComplete:()=>{
                            this.curRotate = 0;
                            //change lastPrize and set opacity to 1
                            state.userData.lastWheelSpinPrize=wheel.prize;
                            this.lastWheelSpinPrize=state.userData.lastWheelSpinPrize;
                            TweenMax.to(this,{opacityVal:1,duration:1,delay:0.1,ease: "power2.out",
                                onComplete:()=>{
                                    this.startSpin=false;
                                }
                            });
                        }
                    });
                }
                else{           //win prize chips/scratch
                    setTimeout(()=>{
                        state.userData.lastWheelSpinPrize=wheel.prize;
                        this.lastWheelSpinPrize=state.userData.lastWheelSpinPrize;
                        this.startSpin=false;
                        if (wheel.prize.prizeAmount===0){
                            this.winResult=this.$t('luckyMsg.m052_06');
                        }
                        else {
                            if (wheel.prize.prizeType === 'chips') {
                                this.winResult = this.$t('luckyMsg.m052_04') + ' '+wheel.prize.prizeAmount.toLocaleString()+' '+this.$t('luckyMsg.m022_1')+'!';
                            }
                            if (wheel.prize.prizeType === 'scratch') {
                                this.winResult = this.$t('luckyMsg.m052_04') + ' '+wheel.prize.prizeAmount+' '+scratchCards[wheel.prize.game].title+' '+this.$t('luckyMsg.m052_05')+'!';
                            }
                        }
                        MyLog(this.winResult);
                    },300);
                }
            },
            getScratchGame(wheel=null){     //if wheel==null then return result from this.lastWheelSpinPrize
                if (wheel){
                    if (wheel.prize.prizeType==='scratch' && (wheel.prize.prizeAmount && wheel.prize.prizeAmount>0) && wheel.prize.game===null)
                        return true;
                    return false;
                }
                else {
                    if (this.lastWheelSpinPrize) {
                        if (this.lastWheelSpinPrize.prizeType === 'scratch'
                            && (this.lastWheelSpinPrize.prizeAmount && this.lastWheelSpinPrize.prizeAmount > 0)
                            && this.lastWheelSpinPrize.game === null)
                            return true;
                    }
                    return false;
                }
            },

            getRandomInt(min, max) {
                return Math.round(this.getRandomNumber(min, max))
            },
            getRandomNumber (min, max) {
                return Math.random() * (max - min) + min
            },

            getScratchStyle(){
                if (this.getScratchGame() || (this.lastWheelSpinPrize && (this.lastWheelSpinPrize.prizeType === 'scratch' && this.winResult)))
                    return true;
                return false;
            },

            getWinNumber(wheel){            //get result item number on reel. If not found, then error, result -1
                const state=this.$store.state;
                if (this.getScratchGame(wheel)){
                    return 9;
                }
                try {
                    if (wheel.prize.game==='panda'){        //panda in 2 or 7 position in array
                        return 2+5*this.getRandomInt(0,1);
                    }
                    let val = '';
                    val = wheel.prize.prizeType === 'chips' ? wheel.prize.prizeAmount.toString() : val;
                    val = wheel.prize.prizeType === 'scratch' ? wheel.prize.game : val;
                    const lc=this.luckyConfig[wheel.prize.prizeType];
                    for (let i=0;i<lc.length;i++){
                        if (lc[i].name===val)
                            return i;
                    }
                }
                catch (e) {
                    console.log('LuckyWheel.getWinNumber catch: ',e.message,'\nstack: ',e.stack)
                }
                return -1;
            },
            resize(){
                let appWidth =  window.innerWidth;
                let appHeight = window.innerHeight;

                let orient=appWidth<appHeight?'V':'H';

                let perW = appWidth / 1920;
                let perH = appHeight / 1080;
                let ss = Math.min(perW, perH);
                ss = Math.max(ss, 0.66);

                let obj={realWidth:appWidth,realHeight:appHeight,ss:ss,orient:orient};
                this.ss=obj.ss;
                this.realWidth=obj.realWidth;
                this.realHeight=obj.realHeight;
            },
            startTimeControl(){
                this.stopTimeControl();
                let dt=this.nextWheelSpinTimestamp-Date.now();
                dt=dt<0?0:dt;
                this.newSpinDelay=dt;
                this.newSpinInterval=setInterval(()=>{
                    dt=this.nextWheelSpinTimestamp-Date.now();
                    dt=dt<0?0:dt;
                    this.newSpinDelay=dt;
                },250);
            },
            stopTimeControl(){
                if (this.newSpinInterval){
                    clearInterval(this.newSpinInterval);
                    this.newSpinInterval=null;
                }
            },
        },
    });
</script>

<style lang="css" scoped>
    .unselectable {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -khtml-user-select: none;    /* Konqueror */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
    }

    .c-components_body{
        /*Body style for components in Zero level on device screen*/
        position: fixed;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding:0;
        margin:0;
        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
    }
    .c-components_abs{
        /*Second style into body for components in Zero level on device screen*/
        display: flex;
        overflow: hidden;
        position: absolute;
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .c-lucky{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-lucky_bg_abs{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-lucky_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-lucky_wheel_reg{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-lucky_img_1{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-lucky_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-lucky_time_bg{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-shadow: 0 7px 15px rgba(0,0,0,.07),inset 0 5px 6px hsla(0,0%,100%,.3);
        background: hsla(0,0%,100%,.2);
    }

    .c-lucky_time_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;

        /*word-wrap: break-word;/**/
        white-space: nowrap;        /*Нет переноса не переносит*/
    }

    .c-lucky_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

</style>
